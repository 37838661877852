.icon {
	height: 100px;
	position: relative;
	width: 100px;
	-webkit-clip-path: polygon(
		30% 0%,
		70% 0%,
		100% 30%,
		100% 70%,
		70% 100%,
		30% 100%,
		0% 70%,
		0% 30%
	);
	clip-path: polygon(
		30% 0%,
		70% 0%,
		100% 30%,
		100% 70%,
		70% 100%,
		30% 100%,
		0% 70%,
		0% 30%
	);
}

.icon img {
	width: 50px;
	height: 50px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

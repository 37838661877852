input[type="file"] {
	display: none;
}

.image-upload-button {
	display: inline-block;
	padding: 10px 20px;
	width: 100%;
	background-color: #0ea6f2;
	color: #fff;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.image-upload-button:hover {
	border: 2px solid;
	border-color: #000;
}

.image-preview {
	max-width: 100%;
	height: auto;
	margin-top: 20px;
	position: relative;
}

.default-preview-image {
	max-width: 100%;
	height: auto;
}

.delete-button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	background-color: #f44336;
	color: #fff;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	position: absolute;
	top: 10px;
	right: 10px;
}

.delete-button:hover {
	background-color: #d32f2f;
}

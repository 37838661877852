header {
	z-index: 1000;
	position: relative;
	width: 100%;
}

.sticky {
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	left: 0;
	border-bottom: 1px solid #cdd3e3;
}

.drawer-side .menu {
	width: 80%;
}

.menu .active {
	color: #fff;
}

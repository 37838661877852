.title {
	position: relative;
	display: inline;
}

.title::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 2px;
	background-color: #007cff;
	left: 0;
	bottom: 0;
}

.feature_img {
	position: relative;
}

.feature_img img {
	position: absolute;
	right: -200px;
	top: -250px;
}

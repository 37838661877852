.project_main {
	position: relative;
	height: 350px;
	width: 100%;
	z-index: 1;
	background-position: 50%;
}

.project_main::before {
	width: 100%;
	height: 37%;
	content: "";
	position: absolute;
	background-color: #2b2b2ca9;
	bottom: 0;
	left: 0;
	transition: all 0.3s ease-in-out;
}

.project-details {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translate(-50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.project_title {
	position: relative;
}

.divider {
	position: relative;
	background-color: #fdfdfd;
	width: 150px;
	height: 2px;
}

.divider::after {
	position: absolute;
	content: "";
	width: 10px;
	height: 2px;
	bottom: 0px;
	left: -30px;
	background-color: #fdfdfd;
}
.divider::before {
	position: absolute;
	content: "";
	width: 10px;
	height: 2px;
	bottom: 0px;
	right: -30px;
	background-color: #fdfdfd;
}

.my_project_slider .swiper-pagination-bullet {
	width: 50px !important;
	height: 5px !important;
	background: lightgray !important;
	border-radius: 10px !important;
	color: var(--pure) !important;
	opacity: 1 !important;
}
.my_project_slider .swiper-pagination-bullet-active {
	width: 100px !important;
	background-color: #42afef !important;
}

.swiper-button-next.slide_next_btn::after,
.swiper-button-prev.slide_prev_btn::after {
	content: none;
}

.slide_next_btn {
	right: 45%;
	top: 95%;
	height: 40px;
	width: 40px;
	border-radius: 50%;
}
.slide_prev_btn {
	left: 45%;
	top: 95%;
	height: 40px;
	width: 40px;
	border-radius: 50%;
}

.project_main:hover::before {
	background-color: #42afef;
}
/* 
@media (max-width: 574px) {
	.slide_next_btn {
		right: calc(37%);
	}
	.slide_prev_btn {
		left: calc(37%);
	}
} */
